import React from 'react';
import Page from './page';

export default function App() {
  return (
    <div>
      <Page />
    </div>
  );
}
